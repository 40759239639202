<template>
  <div class="container">
    <div class="con">
      <div class="basic-wrap">
        <div class="title">
          <h2>游戏名字</h2>
          <input
            v-model="form.title"
            type="text"
            placeholder="请输入游戏名字，以便后期方便查找"
          />
        </div>
        <div class="title">
          <h2>游戏分类</h2>
          <el-cascader
            v-model="cateId"
            :options="treeCate"
            :props="{
              expandTrigger: 'hover',
              label: 'name',
              value: 'cate_id',
              children: 'son',
            }"
            @change="handleChange"
          ></el-cascader>
        </div>
        <button class="btn" @click="saveConfig">保存游戏</button>
      </div>
      <!-- 游戏1 -->
      <div class="game1" v-if="gameId == 1">
        <div class="upload-box">
          <input
            type="file"
            name="fileToUpload"
            @change="uploadImg($event, 0)"
          />
          <img
            class="game1-bg"
            v-if="form.config.length > 0"
            :src="preSrc + form.config[0]"
            @click="deleteImg(gameId, 0)"
            title="点击删除图片"
            alt=""
          />
          <i class="iconfont icon-bianji1"></i>
        </div>
      </div>
      <!-- 游戏1 end -->
      <!-- 游戏2 -->
      <div class="game2" v-if="gameId == 2">
        <div class="game-top">
          <div class="game-title"></div>
          <div class="game-instruction"></div>
        </div>
        <ul class="game-items">
          <li v-for="(item, index) in 8" :key="index" :class="'item' + index">
            <div class="upload-box">
              <i class="iconfont icon-bianji1"></i>
              <input
                type="file"
                name="fileToUpload"
                @change="uploadImg($event, index)"
              />

              <p v-if="form.config[index]">
                <img
                  class="item-pic"
                  :src="preSrc + form.config[index]"
                  @click="deleteImg(gameId, index)"
                  title="点击删除图片"
                  alt=""
                />
              </p>
            </div>
          </li>
        </ul>
      </div>
      <!-- 游戏2 end -->
      <!-- 游戏3 -->
      <div class="game3 flex" v-if="gameId == 3">
        <!-- 左侧 -->
        <div class="game-l">
          <div class="between">
            <div class="game-title"></div>
            <div class="game-instruction"></div>
          </div>
          <div class="disc">
            <img src="@/assets/img/game3/pointer.png" alt="" />
          </div>
          <button class="play"></button>
        </div>
        <!-- 右侧内容 -->
        <div class="game-r">
          <li>
            <img class="sign" src="@/assets/img/game3/1.png" alt="" />
            <div class="upload-box upload-box3">
              <input
                type="file"
                name="fileToUpload"
                @change="uploadImg($event, 0)"
              />
              <img
                class="item-pic"
                v-if="form.config[0]"
                :src="preSrc + form.config[0]"
                @click="deleteImg(gameId, 0)"
                title="点击删除图片"
                alt=""
              />
              <i class="iconfont icon-bianji1"></i>
            </div>
          </li>
          <li>
            <img class="sign" src="@/assets/img/game3/2.png" alt="" />
            <div class="upload-box upload-box3">
              <input
                type="file"
                name="fileToUpload"
                @change="uploadImg($event, 1)"
              />
              <img
                v-if="form.config[1]"
                class="item-pic"
                :src="preSrc + form.config[1]"
                @click="deleteImg(gameId, 1)"
                title="点击删除图片"
                alt=""
              />
              <i class="iconfont icon-bianji1"></i>
            </div>
          </li>
          <li>
            <img class="sign" src="@/assets/img/game3/3.png" alt="" />
            <div class="upload-box upload-box3">
              <input
                type="file"
                name="fileToUpload"
                @change="uploadImg($event, 2)"
              />
              <img
                v-if="form.config[2]"
                class="item-pic"
                :src="preSrc + form.config[2]"
                @click="deleteImg(gameId, 2)"
                title="点击删除图片"
                alt=""
              />
              <i class="iconfont icon-bianji1"></i>
            </div>
          </li>
          <li class="item-4">
            <img class="sign" src="@/assets/img/game3/4.png" alt="" />
            <div class="upload-box upload-box3">
              <input
                type="file"
                name="fileToUpload"
                @change="uploadImg($event, 3)"
              />
              <img
                v-if="form.config[3]"
                class="item-pic"
                :src="preSrc + form.config[3]"
                @click="deleteImg(gameId, 3)"
                title="点击删除图片"
                alt=""
              />
              <i class="iconfont icon-bianji1"></i>
            </div>
          </li>
          <li class="item-5">
            <img class="sign" src="@/assets/img/game3/5.png" alt="" />
            <div class="upload-box upload-box3">
              <input
                type="file"
                name="fileToUpload"
                @change="uploadImg($event, 4)"
              />
              <img
                v-if="form.config[4]"
                class="item-pic"
                :src="preSrc + form.config[4]"
                @click="deleteImg(gameId, 4)"
                title="点击删除图片"
                alt=""
              />
              <i class="iconfont icon-bianji1"></i>
            </div>
          </li>
          <li class="item-6">
            <img class="sign" src="@/assets/img/game3/6.png" alt="" />
            <div class="upload-box upload-box3">
              <input
                type="file"
                name="fileToUpload"
                @change="uploadImg($event, 5)"
              />
              <img
                v-if="form.config[5]"
                class="item-pic"
                :src="preSrc + form.config[5]"
                @click="deleteImg(gameId, 5)"
                title="点击删除图片"
                alt=""
              />
              <i class="iconfont icon-bianji1"></i>
            </div>
          </li>
          <li class="item-7">
            <img class="sign" src="@/assets/img/game3/7.png" alt="" />
            <div class="upload-box upload-box3">
              <input
                type="file"
                name="fileToUpload"
                @change="uploadImg($event, 6)"
              />
              <img
                v-if="form.config[6]"
                class="item-pic"
                :src="preSrc + form.config[6]"
                @click="deleteImg(gameId, 6)"
                title="点击删除图片"
                alt=""
              />
              <i class="iconfont icon-bianji1"></i>
            </div>
          </li>
          <li class="item-8">
            <img class="sign" src="@/assets/img/game3/8.png" alt="" />
            <div class="upload-box upload-box3">
              <input
                type="file"
                name="fileToUpload"
                @change="uploadImg($event, 7)"
              />
              <img
                v-if="form.config[7]"
                class="item-pic"
                :src="preSrc + form.config[7]"
                @click="deleteImg(gameId, 7)"
                title="点击删除图片"
                alt=""
              />
              <i class="iconfont icon-bianji1"></i>
            </div>
          </li>
        </div>
      </div>
      <!-- 游戏3 end -->
      <p class="tip" v-if="gameId != 4">
        <img src="@/assets/img/common/warn.png" alt="" />
        建议上传{{ tipSize }}，大小不超过300KB的png图片，以保证游戏效果。
      </p>
      <!-- 游戏4 -->
      <div class="game4-wrap" v-if="gameId == 4">
        <div class="game4">
          <div class="bg-wrap">
            <h2 class="bg-title">上传背景图</h2>
            <div class="upload-wrap" v-if="!bgSrc">
              <img
                class="upload-img"
                src="@/assets/img/game3/add-img.png"
                alt=""
              />
              <input type="file" name="fileToUpload" @change="uploadBg" />
            </div>
            <div class="bg-pic" v-else>
              <img class="bg-img" :src="preSrc + bgSrc" alt="" />
              <span class="iconfont icon-shanchu" @click="delBg"></span>
            </div>
            <p class="tip">
              建议上传1280*720，大小不超过1M的png图片，以保证游戏效果。
            </p>
          </div>
          <!-- <div class="bg-wrap">
            <h2 class="bg-title">游戏说明</h2>
            <textarea
              class="game-info"
              v-model="game_title"
              placeholder="请输入游戏说明"
            ></textarea>
          </div> -->
        </div>
        <div class="game4-lists">
          <div class="game-item" v-for="(item, i) in 10" :key="i">
            <h3 class="game-index">第{{ i + 1 }}题</h3>
            <div class="game-ques">
              <label class="ques-title">问题:</label>
              <div class="ans-input" :id="'editorQues' + (i + 1)"></div>
            </div>
            <div class="game-ques">
              <label class="ques-title">答案:</label>
              <div class="ans-input" :id="'editorElem' + (i + 1)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import E from "wangeditor";
export default {
  data() {
    return {
      preSrc: config.src,
      gameId: "", //游戏id
      form: {
        title: "",
        config: [], //图片
      },
      tipSize: "",
      quesList: [],
      ansList: [],
      treeCate: [],
      cateId: [],
      bgSrc: "",
      game_title: "",
    };
  },
  created() {
    let _self = this;
    let id = _self.$route.params.id;
    _self.gameId = id;
    if (id == 1) {
      _self.tipSize = "1210*590px";
    } else if (id == 2) {
      _self.form.config = ["", "", "", "", "", "", "", ""];
      _self.tipSize = "正方形（180*180px）";
    } else {
      _self.form.config = ["", "", "", "", "", "", "", ""];
      _self.tipSize = "正方形（150*150px）";
    }
    _self.initData(id);
    _self.getCateTree();
  },
  mounted() {
    if (this.gameId == 4) {
      this.getEditor('ansList','editorElem')
      this.getEditor('quesList','editorQues')
    }
  },
  methods: {
    //游戏分类（树形）
    getCateTree() {
      let _self = this;
      _self.axios.post(config.url.treeCate).then((res) => {
        if (res.code == 200) {
          _self.treeCate = res.data && res.data.length > 0 ? res.data : [];
          _self.loading = false;
          _self.listLoad = false;
        } else if (res.code != 200 && res.code != 401) {
          _self.showTip("error", res.msg);
          _self.loading = false;
          _self.listLoad = false;
        }
      });
    },
    getEditor(key, id) {
      for (let i = 0; i < 10; i++) {
        let ele = document.getElementById(id+(i+1))
        let editor = new E(ele);
        editor.config.onchange = (html) => {
          this[key][i] = html;
        };
        editor.config.menus = [
          "bold", // 粗体
          "foreColor", // 文字颜色
          "fontSize", // 字体大小
        ];
        editor.config.showFullScreen = false;
        //过滤掉复制文本中自带的样式,默认开启
        editor.config.pasteFilterStyle = false;
        editor.create();
      }
    },
    // 初始化
    initData(id) {
      let _self = this;
      _self.axios
        .post(config.url.gameInfo, _self.$qs.stringify({ game_id: id }))
        .then((res) => {
          if (res.code == 200) {
            _self.loading = false;
            _self.info = res.data;
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    // 上传图片
    uploadImg(e, index) {
      let _self = this;
      let fileImg = e.target.files[0];
      const formData = new FormData();
      formData.append("img", fileImg);
      formData.append("path", "game");
      if (fileImg) {
        _self.axios.post(config.url.uploadPic, formData).then((res) => {
          if (res.code == 200) {
            let config = _self.form.config;
            // if (config[index] != '') {
            _self.$set(_self.form.config, index, res.data.path);
            // } else {
            //   _self.form.config.push(res.data.path);
            // }
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
      }
    },
    // 删除图片
    deleteImg(id, index) {
      let _self = this;
      if (id == 1) {
        _self.form.config = [];
        return;
      }
      _self.$set(_self.form.config, index, "");
    },
    handleChange(e) {
      this.cateId = e;
    },
    // 上传背景图片
    uploadBg(e) {
      let _self = this;
      let fileImg = e.target.files[0];
      const formData = new FormData();
      formData.append("img", fileImg);
      formData.append("path", "game");
      if (fileImg) {
        _self.axios.post(config.url.uploadPic, formData).then((res) => {
          if (res.code == 200) {
            _self.bgSrc = res.data.path;
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
      }
    },
    // 删除背景图片
    delBg() {
      this.bgSrc = "";
    },
    // 提交
    saveConfig() {
      let _self = this;
      let configs = _self.form.config,
        title = _self.form.title,
        cateId = _self.cateId;
      if (!title) {
        _self.showTip("error", "请输入游戏标题");
        return;
      }
      if (cateId.length == 0) {
        _self.showTip("error", "请选择游戏分类");
        return;
      }
      let params = {};
      if (_self.gameId != 4) {
        if (configs.length <= 0) {
          _self.showTip("error", "请先上传图片");
          return;
        }
        let emptyLen = 0;
        for (let i in configs) {
          if (configs[i] == "") {
            emptyLen += 1;
          }
        }
        if (emptyLen == configs.length) {
          _self.showTip("error", "请先上传图片");
          return;
        }
        params = {
          game_id: _self.gameId,
          config: JSON.stringify(configs),
          title: title,
          cate_id: cateId[cateId.length - 1],
        };
      } else {
        let quesList = _self.quesList;
        let ansList = _self.ansList;
        let newArr = [];
        quesList.forEach((item, i) => {
          if (item.trim() != "" && ansList[i] && ansList[i].trim() != "") {
            newArr.push({
              ques: item,
              ans: ansList[i],
              status: 1,
            });
          }
        });
        // if (_self.game_title == "") {
        //   _self.showTip("error", "请输入游戏说明");
        //   return;
        // }
        if (newArr.length == 0) {
          _self.showTip("error", "请至少填写一个问题");
          return;
        }
        params = {
          game_id: _self.gameId,
          config: JSON.stringify(newArr),
          title: title,
          cate_id: cateId[cateId.length - 1],
          // game_title: _self.game_title,
          background: _self.bgSrc,
        };
      }
      _self.axios
        .post(config.url.saveConfig, _self.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            _self.form = {
              title: "",
              config: [],
            };
            _self.showTip("success", res.msg);
            _self.$router.replace("/profile/game");
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  background: #fff;
  .basic-wrap {
    display: flex;
    align-items: center;
  }
  .upload-box {
    input,
    i {
      cursor: pointer;
    }
  }
}
.con {
  margin: 60px auto 0;
  .title {
    width: 460px;
    margin-right: 50px;
    flex-shrink: 0;
    h2 {
      font-size: 30px;
      margin-bottom: 20px;
    }
    input {
      width: 100%;
      font-size: 16px;
      padding: 10px 0;
      border: none;
      border-bottom: 1px solid #bebebe;
      box-sizing: border-box;
      outline: none;
      margin-bottom: 30px;
      height: 50px;
    }
    input::placeholder {
      color: #bebebe;
    }
    input:focus {
      border: none;
      border-bottom: 1px solid $color-main;
    }
    .el-cascader {
      margin-bottom: 30px;
      width: 100%;

      ::v-deep .el-input__inner {
        border: none !important;
        border-radius: 0 !important;
        border-bottom: 1px solid #bebebe !important;
        width: 100% !important;
      }
    }
  }
  .upload-box {
    i {
      width: 100%;
      height: 100%;
      font-size: 22px;
      color: #fff;
      background: $color-main;
      border-radius: 50%;
      padding: 10px;
      box-sizing: border-box;
      position: relative;
      top: -3px;
      left: 143px;
      cursor: pointer;
    }
    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
    }
    img {
      position: absolute;
      width: 150px;
      height: 150px;
      object-fit: contain;
    }
  }
  .game {
    margin-bottom: 60px;
  }
  // 游戏1
  .game1 {
    width: 1200px;
    height: 675px;
    margin: 0 auto;
    background: url("../../assets/img/game1/game1.jpg") center center no-repeat;
    background-size: 100%;
    box-sizing: border-box;
    position: relative;
    .game1-bg {
      width: 1100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 504px;
      margin: 28px 0 0 -1px;
      object-fit: contain;
    }
    .upload-box {
      cursor: default;
      input {
        width: 80px;
        height: 80px;
        top: 100px;
        left: 1073px;
      }
      i {
        text-align: center;
        position: absolute;
        width: 80px;
        height: 80px;
        font-size: 40px;
        line-height: 57px;
        top: 100px;
        left: 1073px;
      }
      img {
        cursor: pointer;
      }
    }
  }
  // 游戏2
  .game2 {
    width: 1200px;
    height: 675px;
    margin: 0 auto;
    background: url("../../assets/img/game2/bg.png") center center no-repeat;
    background-size: 100%;
    padding: 20px 40px;
    box-sizing: border-box;
    .game-top {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .game-title {
      width: 460px;
      height: 109px;
      background: url("../../assets/img/game2/title.png") center center
        no-repeat;
      background-size: 100%;
      margin-right: 180px;
      /* 解决缩小图片后，图片变模糊 */
      image-rendering: -moz-crisp-edges;
      image-rendering: -moz-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;
    }
    .game-instruction {
      width: 170px;
      height: 70px;
      /* 解决缩小图片后，图片变模糊 */
      image-rendering: -moz-crisp-edges;
      image-rendering: -moz-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;
    }
    .game-items {
      flex-wrap: wrap;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .game-items li {
      position: relative;
      width: 230px;
      height: 230px;
      background: #fff;
      border-radius: 30px;
    }

    .item-bg {
      width: 100%;
    }
    .item-img-box {
      width: 210px;
      height: 210px;
      border-radius: 20px;
      margin: 10px auto;
      padding: 10px;
      box-sizing: border-box;
    }
    .item-img-box img {
      border-radius: 20px;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
      /* 解决缩小图片后，图片变模糊 */
      image-rendering: -moz-crisp-edges;
      image-rendering: -moz-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;
    }
    .upload-box {
      cursor: default;
      width: 210px;
      height: 210px;
      margin: 10px auto;
      position: relative;
      input {
        width: 42px;
        height: 42px;
        top: -19px;
        left: 185px;
      }
      i {
        position: absolute;
        top: -19px;
        left: 185px;
        width: 42px;
        height: 42px;
        font-size: 22px;
        color: #fff;
        background: $color-main;
        border-radius: 50%;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 180px;
        height: 180px;
        object-fit: contain;
        margin: 15px;
      }
      input:hover,
      i:hover,
      img:hover {
        cursor: pointer;
      }
    }
    li p {
      width: 210px;
      height: 210px;
      border-radius: 30px;
    }
    li.item0 {
      margin: 0 30px 0 40px;
    }
    li.item0 p {
      background: rgb(254, 154, 154);
    }
    li.item1 {
      margin: 30px 30px 0 0;
    }
    li.item1 p {
      background: rgb(155, 151, 255);
    }
    li.item2 {
      margin: 20px 30px 0 0;
    }
    li.item2 p {
      background: rgb(243, 187, 131);
    }
    li.item3 {
      margin-right: 30px;
    }
    li.item3 p {
      background: rgb(53, 203, 205);
    }
    li.item4 {
      margin-right: 50px;
    }
    li.item4 p {
      background: rgb(103, 152, 255);
    }
    li.item5 {
      margin: 35px 50px 0 0;
    }
    li.item5 p {
      background: rgb(19, 190, 157);
    }
    li.item6 {
      margin: 30px 50px 0 0;
    }
    li.item6 p {
      background: rgb(228, 149, 158);
    }
    li.item7 p {
      background: rgb(182, 176, 78);
    }
  }
  // 游戏2 end
  //  游戏3
  .game3 {
    width: 1200px;
    height: 675px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px;
    background: rgba(255, 166, 173);
    border: 4px solid #333;
    border-radius: 10px;
    box-sizing: border-box;
    .game-l {
      width: 430px;
      margin-right: 50px;
      background: rgba(231, 106, 104);
      border-radius: 20px;
      height: 100%;
      padding: 30px 20px;
      box-sizing: border-box;
    }
    .game-title {
      width: 215px;
      height: 64px;
      background: url("../../assets/img/game3/title.png") center center
        no-repeat;
      background-size: 100%;
      /* 解决缩小图片后，图片变模糊 */
      image-rendering: -moz-crisp-edges;
      image-rendering: -moz-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;
    }

    .disc {
      width: 370px;
      height: 370px;
      background: url("../../assets/img/game3/turntable.png") center center
        no-repeat;
      background-size: 100%;
      position: relative;
      margin: 40px auto 10px;
    }
    .disc img {
      width: 370px;
      height: 370px;
      object-fit: cover;
      border-radius: 50%;
    }
    .play {
      width: 200px;
      height: 120px;
      background: url("../../assets/img/game3/start.png") center center
        no-repeat;
      background-size: 100%;
      margin-left: 90px;
    }
    /* 右 */
    .game-r {
      width: 780px;
      height: 100%;
      background: rgba(231, 106, 104);
      border-radius: 20px;
      padding: 10px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }
    .game-r li {
      width: 190px;
      height: 190px;
      background: rgba(255, 166, 173);
      border-radius: 10px;
      position: relative;
    }
    .game-r li div {
      width: 170px;
      height: 170px;
      background: #fff;
      border-radius: 10px;
      margin: 10px auto;
    }

    .game-r .sign {
      position: absolute;
      top: -10px;
      left: -20px;
      z-index: 3;
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 50%;
      /* 解决缩小图片后，图片变模糊 */
      image-rendering: -moz-crisp-edges;
      image-rendering: -moz-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;
    }
    .upload-box3 {
      input {
        left: 153px;
        top: -2px;
        width: 42px;
        height: 42px;
      }
      img {
        width: 150px;
        height: 150px;
        object-fit: contain;
        margin: 10px;
        cursor: pointer;
      }
    }

    .item-4 {
      top: 5px;
      left: 462px;
    }
    .item-5 {
      top: 210px;
      left: 235px;
    }
    .item-6 {
      top: 211px;
      left: -231px;
    }
    .item-7 {
      left: -65px;
      top: 6px;
    }
    .item-8 {
      top: -199px;
      left: -406px;
    }
  }

  // 游戏3 end

  .tip {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 50px 0 60px 0;
    font-size: 14px;
    color: $font-color-81;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  // 游戏4
  .game4-wrap {
    .game4 {
      display: flex;
      margin-bottom: 50px;
      h2 {
        font-size: 30px;
        margin-bottom: 30px;
      }
    }
    .bg-wrap {
      width: 460px;
      margin-right: 50px;
      .upload-wrap {
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        display: flex;
        width: 72px;
        height: 72px;
        img {
          width: 72px;
          height: 72px;
        }
        input {
          position: absolute;
          z-index: 1;
          left: 0;
          top: 0;
          opacity: 0;
          width: 100%;
          height: 100%;
        }
      }
      .bg-pic {
        position: relative;
        width: 128px;
        height: 72px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        span {
          width: 24px;
          height: 24px;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color-main;
        }
      }
      .game-info {
        border: 1px solid #bebebe;
        padding: 6px;
        width: 100%;
        height: 100px;
        resize: none;
        font-size: 16px;
        background: transparent;
        outline: none;
        box-sizing: border-box;
      }
      .game-info::placeholder {
        color: #bebebe;
      }
      .game-info:focus {
        border-color: $color-main;
      }
    }
    .tip {
      margin: 10px 0;
      line-height: 1;
      height: 20px;
      font-size: 14px;
    }
    .game4-lists {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .game-item {
        width: 45%;
        margin: 10px 0;
        .game-ques {
          display: flex;
          margin: 16px 0;
          .ques-title {
            width: 48px;
            margin-right: 2px;
            flex-shrink: 0;
            font-size: 18px;
            line-height: 36px;
            color: #5a5a5a;
          }
          .ques-input {
            width: 100%;
            outline: none;
            background: transparent;
            border: 1px solid #eaeaea;
            padding: 10px 12px;
            font-size: 16px;
          }
          .ans-input {
            width: 100%;
          }
          input::placeholder {
            color: #bebebe;
          }
          input:focus {
            border: 1px solid $color-main;
          }
        }
      }
      .game-index {
        font-size: 24px;
      }
    }
  }
}
</style>
<style lang="scss">
.w-e-text-container {
  height: 48px !important;
  border: 1px solid #eaeaea !important;
  .w-e-text {
    min-height: 48px !important;
  }
}
.w-e-toolbar {
  border: 1px solid #eaeaea !important;
  border-bottom: none !important;
}
.w-e-text-container .placeholder {
  color: #bebebe !important;
}
.w-e-toolbar .w-e-droplist{
  bottom: 0 !important;
  top:auto !important;
}
.w-e-toolbar .w-e-droplist ul.w-e-block,
.w-e-toolbar .w-e-droplist ul.w-e-list{
  background: #fff !important;
}
.w-e-toolbar .w-e-droplist .w-e-dp-title{
  background: #fff !important;
}
</style>
